

const formatToBrDate = (date:string) => {
  const partes = date.split('-');

  if (partes.length !== 3) {
    return 'Data inválida';
  }

  const ano = partes[0];
  const mes = partes[1];
  const dia = partes[2];
  return `${dia}/${mes}/${ano}`;
};

export default formatToBrDate;
