import FooterScreen from '../../components/footer/footer.screen';
import * as React from 'react';
import HeaderNavBarScreen from '../../components/headerNavBar/headerNavBar.screen';
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem, Paper,
  Select,
  SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { getTokenInStorage } from '../../services/token/token.service';
import axios from 'axios';
import formatToBrDate from '../../services/formatters/formatToBrDate.service';
import formatToCurrency from '../../services/formatters/formatToCurrency.service';

const FindContractsScreen = () => {
  const [contractStatusSelected, setContractStatusSelected] = useState<string>('1');
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState([]);

  const cockpitFindContracts = async () => {
    try {
      const statusSelected = contractStatusSelected

      setLoading(true);
      const { access_token } = getTokenInStorage();
      const response = await axios.get(`https://api.nobli.com.br/contracts/list/by-admin?status_code=${statusSelected}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (response.status === 200) {
        setContracts(sortableContractList(response.data));
        return;
      }

      console.log(response);
      alert('Falha ao buscar contratos');
    } catch (e) {
      console.error(e);
      alert('Falha ao buscar contratos');
    } finally {
      setLoading(false);
    }
  };


  const sortableContractList = (contracts:[]) => {
    return contracts.sort((contractA:any, contractB:any) => {
      if (contractA.first_name > contractB.first_name) {
        return 1;
      }
      if (contractB.first_name > contractA.first_name) {
        return -1;
      }
      return 0;
    })
  }

  return (

    <>
      <HeaderNavBarScreen />
      <Box sx={{ height: { md: '22vh', xs: '20vh' }, pt: 5, background: '#01576e'}}>
        <Container>
          <Box sx={{ color: '#ffffff' }}>
            <Box sx={{ paddingTop: { md: '5rem', xs: '3rem' } }}>
              <Typography sx={{fontSize:'36px', fontWeight:'bold', lineHeight:"1.15"}}>
                Buscar contrato
              </Typography>
              <Typography>
                Aqui você pode filtrar contratos.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ py:'6vh'}}>
        <Container>
          <Box>
            <Grid container spacing={3} alignItems={'center'}>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth variant={'filled'}>
                  <InputLabel id="select-label-contract-status">Status do contrato</InputLabel>
                  <Select
                    labelId="select-label-contract-status"
                    label="Status do contrato"
                    value={contractStatusSelected}
                    onChange={(event) => setContractStatusSelected(event.target.value)}
                  >
                    <MenuItem value={'1'} selected>Ativo</MenuItem>
                    <MenuItem value={'4'}>Encerrado</MenuItem>
                    <MenuItem value={'3'}>Cancelado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12}>
                <LoadingButton fullWidth loading={loading} variant={'contained'} onClick={() => cockpitFindContracts()}>
                  Buscar
                </LoadingButton>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box sx={{ py:'5vh'}}>
                  <TableContainer component={Paper}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Nª CCB</TableCell>
                          <TableCell align="center">Nª contrato</TableCell>
                          <TableCell align="center">Reasons</TableCell>
                          <TableCell align="center">Valor</TableCell>
                          <TableCell align="center">Parcela</TableCell>
                          <TableCell align="center">Nome</TableCell>
                          <TableCell align="center">Documento</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contracts.map((contract:any) => (
                          <TableRow  key={contract.id}>
                            <TableCell align="center">{contract.ccb_number}</TableCell>
                            <TableCell align="center"><a href={`/cockpit/contrato-detalhe?contractId=${contract.id}`}>{contract.contract_number}</a></TableCell>
                            <TableCell align="center">{contract.reason_description}</TableCell>
                            <TableCell align="center">{formatToCurrency(contract.granted_amount)}</TableCell>
                            <TableCell align="center">{contract.total_installment}</TableCell>
                            <TableCell align="center" title={`${contract.first_name} ${contract.last_name}`} sx={{textTransform:'capitalize'}}>
                              {`${contract.first_name} ${contract.last_name}`.toLowerCase().substring(0, 55)}
                            </TableCell>
                            <TableCell>
                              {contract.document_number}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <FooterScreen />
    </>
  )
}

export default FindContractsScreen;


