import HeaderNavBarScreen from '../../components/headerNavBar/headerNavBar.screen';
import * as React from 'react';
import { Box, Container, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import axios, { AxiosError } from 'axios';
import { AccessTokenType, isLogged, saveTokenInStorage } from '../../services/token/token.service';
import FooterScreen from '../../components/footer/footer.screen';
import { useNavigate } from 'react-router-dom';


const CockpitLoginScreen = () => {
  const [authBody, setAuthBody] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const changAuthBody = (name: any, value: any) => {
    setAuthBody({ ...authBody, [name]: value });
  };

  const authenticateUser = async () => {
    try {
      const body = {
        email: authBody.email,
        password: authBody.password
      };
      if (emailIsInvalid(body.email) || body.password.length === 0) {
        alert('Email ou senha inválidos!');
        return;
      }
      setLoading(true);
      const response = await axios.post<AccessTokenType>(`https://api.nobli.com.br/authentication/login`, body);

      if (response.status === 200) {
        const { data } = response;
        saveTokenInStorage(data);
        window.location.href = '/cockpit';
        return;
      }
      console.error(response);
      alert('Login inválido');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 401) {
          alert('Login Invalido!');
          return;
        }
      }
      console.error(error);
      alert('Falha ao realizar login');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(isLogged()){
      navigate("/cockpit");
    }
  }, []);

  return (
    <>
      <HeaderNavBarScreen />
      <Box sx={{ height: { md: '22vh', xs: '20vh' }, pt: 5, background: '#01576e' }}>
        <Container>
          <Box sx={{ color: '#ffffff' }}>
            <Box sx={{ paddingTop: { md: '5rem', xs: '3rem' } }}>
              <Typography sx={{ fontSize: '36px', fontWeight: 'bold', lineHeight: '1.15' }}>
                Cockpit
              </Typography>
              <Typography>
                Autenticação
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ py:{ md: '20vh', xs: '16vh' }}}>
        <Container>
          <Box>
            <Grid container spacing={4} justifyContent={'space-between'} alignItems='center'>
              <Grid item md={4} xs={12}>
                <TextField name={'email'} type={'email'} value={authBody.email}
                           onChange={((event) => changAuthBody(event.target.name, event.target.value))} fullWidth
                           label='Email' variant='filled' placeholder={'email@email.com.br'} focused />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField name={'password'} type={'password'}
                           onChange={((event) => changAuthBody(event.target.name, event.target.value))}
                           fullWidth label='Senha' variant='filled' focused />
              </Grid>
              <Grid item md={3} xs={12}>
                <LoadingButton variant={'contained'} onClick={() => authenticateUser()} loading={loading}>
                  Entrar
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <FooterScreen />
    </>
  );
};

export default CockpitLoginScreen;

const emailIsInvalid = (email: string) => {
  // Expressão regular para validar um endereço de e-mail
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return !regexEmail.test(email);
};
