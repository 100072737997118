import HeaderNavBarScreen from '../../components/headerNavBar/headerNavBar.screen';
import {
  Box, Button, CircularProgress,
  Container, FormControl,
  Grid, InputLabel,
  MenuItem,
  Paper, Select,
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Typography
} from '@mui/material';
import * as React from 'react';
import FooterScreen from '../../components/footer/footer.screen';
import { getTokenInStorage, isLogged } from '../../services/token/token.service';
import axios from 'axios';
import { useEffect, useState } from 'react';
import LoadingContractDetail from './loadingContractDetail.component';
import formatToCurrency from '../../services/formatters/formatToCurrency.service';
import formatToBrDate from '../../services/formatters/formatToBrDate.service';
import UpdateStatusModal from './updateStatus.modal';


const ContractDetailScreen = () => {

  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState<any>({});
  const [modalStatus, setModalStatus] = useState(false);

  const handleModal = (openModal?: boolean) => {
    if (openModal === undefined) {
      setModalStatus(!modalStatus);
      return;
    }
    setModalStatus(openModal);

  };

  const getContractId = () => {
    const url = new URL(document.location.href);
    return url.searchParams.get('contractId');
  };


  const findContractById = async () => {
    try {
      const contractId = getContractId();

      const { access_token } = getTokenInStorage();
      const response = await axios.get(`https://api.nobli.com.br/contracts/${contractId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });

      if (response.status === 200) {
        const { data } = response;

        let newContract: any = {
          ...data
        };

        newContract.installments = newContract.installments.sort((a: any, b: any) => a.installment_number - b.installment_number);
        newContract.installments = newContract.installments.map((installment: any) => {
          return {
            ...installment,
            loading: false
          };
        });
        setContract(newContract);
        return;
      }

      if (response.status === 404) {
        alert('Contrato não localizado');
        return;
      }

      alert('Falha ao buscar contrato');
    } catch (e) {
      alert('Erro ao buscar contrato');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    findContractById();
  }, []);

  const buildDownloadButton = (installment: any) => {
    if (installment.signed_url_payment_slip) {
      return <a href={installment.signed_url_payment_slip} target={'_blank'}>Baixar boleto</a>;
    }
    return '';
  };


  const changeLoadingInstallment = (installmentId: string, loading: boolean) => {
    const installments = contract.installments.map((installment: any) => {
      if (installment.id === installmentId) {
        installment.loading = loading;
      }
      return installment;
    });

    setContract({ ...contract, installments });
  };

  const updateInstallmentStatus = async (installmentId: string, statusInstallment: any) => {
    try {
      const body = {
        paid_installment: parseInt(statusInstallment)
      };
      changeLoadingInstallment(installmentId, true);
      const { access_token } = getTokenInStorage();
      const response = await axios.put(`https://api.nobli.com.br/contracts/${installmentId}/change-installment-status`, body, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });

      if (response.status === 200) {
        alert('Parcela atualizada com sucesso!');
        await findContractById();
        return;
      }

      alert('Falha ao parcela');
      console.error(response);
    } catch (e) {
      alert('Falha ao atualizar status');
      console.error(e);
      changeLoadingInstallment(installmentId, false);
    }
  };

  return (
    <>
      <HeaderNavBarScreen />
      <UpdateStatusModal statusModal={modalStatus} handleCloseModal={handleModal} contract={contract}
                         findContractById={findContractById} />
      <Box sx={{ height: { md: '22vh', xs: '20vh' }, pt: 5, background: '#01576e' }}>
        <Container>
          <Box sx={{ color: '#ffffff' }}>
            <Box sx={{ paddingTop: { md: '5rem', xs: '3rem' } }}>
              <Typography sx={{ fontSize: '36px', fontWeight: 'bold', lineHeight: '1.15' }}>
                Detalhe do contrato
              </Typography>
              <Typography>
                Aqui você encontra informações detalhadas do contrato
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <LoadingContractDetail loading={loading} />
      {!loading &&
        <Box sx={{ py: '6vh' }}>
          <Container>
            <Grid container spacing={4}>
              <Grid item md={4}>
                <Box p={1}>
                  <Paper>
                    <Box p={2}>
                      <Typography fontWeight={'bold'}>
                        Contrato
                      </Typography>
                      <div style={{ padding: '1rem 0 1rem 0' }}>
                        <div style={{ color: '#707070' }}>Nº contrato</div>
                        <div style={{ color: 'black' }}>{contract.contract_number}</div>
                      </div>
                      <div style={{ padding: '1rem 0 1rem 0' }}>
                        <div style={{ color: '#707070' }}>Nº CCB</div>
                        <div style={{ color: 'black' }}>{contract.ccb_number}</div>
                      </div>
                      <div style={{ padding: '0 0 1rem 0' }}>
                        <div style={{ color: '#707070' }}>Valor financiado</div>
                        <div style={{ color: 'black' }}>{formatToCurrency(contract?.granted_amount?.toString())}</div>
                      </div>
                      <div style={{ padding: '0 0 1rem 0' }}>
                        <div style={{ color: '#707070' }}>Parcelas</div>
                        <div style={{ color: 'black' }}>{contract.installments?.length?.toString()}</div>
                      </div>
                      <div style={{ padding: '0 0 1rem 0' }}>
                        <div style={{ color: '#707070' }}>Status do Contrato</div>
                        <div
                          style={{ color: 'black' }}>{`${contract.status_description}(${contract.status_code})/ ${contract.reason_description}(${contract.reason_code})`}</div>
                        <div style={{ color: 'black' }}><Button variant={'text'} onClick={() => handleModal(true)}>Atualizar
                          Status</Button></div>
                      </div>
                    </Box>
                  </Paper>
                </Box>
                <Box p={1}>
                  <Paper>
                    <Box p={2}>
                      <Typography fontWeight={'bold'}>
                        Dados Pessoais
                      </Typography>
                      <div style={{ padding: '1rem 0 1rem 0' }}>
                        <div style={{ color: '#707070' }}>Nome</div>
                        <div style={{ color: 'black' }}>{`${contract.first_name} ${contract.last_name}`}</div>
                      </div>
                      <div style={{ padding: '0 0 1rem 0' }}>
                        <div style={{ color: '#707070' }}>Documento</div>
                        <div style={{ color: 'black' }}>{contract.document_number}</div>
                      </div>
                      <div style={{ padding: '0 0 1rem 0' }}>
                        <div style={{ color: '#707070' }}>Nascimento</div>
                        <div style={{ color: 'black' }}>{formatarDate(contract.birthday)}</div>
                      </div>
                      <div style={{ padding: '0 0 1rem 0' }}>
                        <div style={{ color: '#707070' }}>Telefone</div>
                        <div
                          style={{ color: 'black' }}>{`${contract.cell_phone_prefix} ${contract.cell_phone_number}`}</div>
                      </div>
                      <div style={{ padding: '0 0 1rem 0' }}>
                        <div style={{ color: '#707070' }}>E-mail</div>
                        <div style={{ color: 'black' }}>{contract.email}</div>
                      </div>
                    </Box>
                  </Paper>
                </Box>

              </Grid>
              <Grid item md={8}>
                <Box pb={'2rem'}>
                  <Typography sx={{ color: '#707070', fontSize: '18px' }}>
                    Parcelas
                  </Typography>
                </Box>
                <Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ width: '100%' }} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center'>Nª parcelas</TableCell>
                          <TableCell align='center'>Vencimento</TableCell>
                          <TableCell align='center'>Valor</TableCell>
                          <TableCell align='center' sx={{ minWidth: '80px' }}>&nbsp;</TableCell>
                          <TableCell align='center'>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contract.installments.map((installment: any) => (
                          <TableRow key={installment.id}>
                            <TableCell align='center'>{installment.installment_number}</TableCell>
                            <TableCell align='center'>{formatToBrDate(installment.due_date)}</TableCell>
                            <TableCell align='center'>{formatToCurrency(installment.amount)}</TableCell>
                            <TableCell align='center'>{buildDownloadButton(installment)}</TableCell>
                            <TableCell align='center'>

                              {installment.loading && <CircularProgress />}
                              {!installment.loading &&
                                <FormControl fullWidth variant={'outlined'} size={'small'}>
                                  <InputLabel id='selecione-seu-contrato-select-label'>Status</InputLabel>
                                  <Select
                                    labelId='selecione-seu-contrato-select-label'
                                    id='selecione-seu-contrato-select'
                                    value={installment.paid_installment}
                                    label='Selecione seu contrato'
                                    onChange={(event) => updateInstallmentStatus(installment.id, event.target.value)}
                                  >
                                    <MenuItem value={0}>Em aberto</MenuItem>
                                    <MenuItem value={1}>Pago</MenuItem>
                                  </Select>
                                </FormControl>
                              }


                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
      <FooterScreen />
    </>
  );
};

export default ContractDetailScreen;

const formatarDate = (date: string) => {
  const partes = date?.split('-');

  if (partes?.length !== 3) {
    return 'Data inválida';
  }

  const ano = partes[0];
  const mes = partes[1];
  const dia = partes[2];
  return `${dia}/${mes}/${ano}`;
};
