import * as React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import HeaderNavBarScreen from '../../components/headerNavBar/headerNavBar.screen';
import FooterScreen from '../../components/footer/footer.screen';


const HomeScreen = () => {
  return <>
    <HeaderNavBarScreen />
    <Box component='main' sx={{
      height: { md: '80vh', xs: '55vh' },
      pt: 5,
      background: 'url(\'/assets/Group_11-1.svg\')',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat !important',
      backgroundPosition: 'center center'
    }}>
      <Container>
        <Box height={'100vh'} sx={{ color: '#ffffff' }}>
          {/*430px*/}
          <Box sx={{ paddingTop: { md: '22rem', xs: '10rem' } }}>
            <Grid container spacing={2}>
              <Grid item md={10} xs={12}>
                <Typography fontWeight={'bold'} lineHeight={'1'} sx={{ fontSize: { md: '58px', xs: '35px' } }}>
                  Crédito com garantia de investimentos.
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography marginTop={'margin-top'} lineHeight={'1.35'} sx={{ fontSize: { md: '24px', xs: '18px' } }}>
                  Utilize seus investimentos financeiros como garantia para obter as melhores taxas e condições de
                  crédito, sem precisar abrir mão dos seus rendimentos.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
    <FooterScreen />
  </>;
};

export default HomeScreen;
