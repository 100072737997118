import { Box, Container, Grid, LinearProgress, Skeleton } from '@mui/material';
import * as React from 'react';

const LoadingContractDetail = (props: { loading:boolean }) => {
  const {loading} = props;
  return(
    <>
      {loading &&
        <Box>
          <LinearProgress />
          <Box  sx={{ py:'6vh'}}>
            <Container>
              <Grid container spacing={4}>
                <Grid item md={4}>
                  <Skeleton variant="rounded" width={'100%'} height={'45vh'} />
                </Grid>
                <Grid item md={8}>
                  <Skeleton variant="rounded" width={'100%'} height={'20vh'} />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      }
    </>
  )
}
export default LoadingContractDetail;
