import { createTheme } from '@mui/material';


const theme = createTheme({
  components:{
    MuiButton: {
      styleOverrides: {
        root: {
          // Some CSS
          fontSize: '18px/16px',
          fontFamily: '\'Open Sans\', sans-serif',
          borderRadius: '50px',
          // border: '1px solid',
          fontWeight: '700'
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#01576e',
      main: '#01576e',
      dark: '#01576e',
      contrastText: '#FFF',
    },
    secondary: {
      light: '#FFF',
      main: '#FFF',
      dark: '#FFF',
      contrastText: '#01576E',
    },
  },
});


export default theme;
