import HeaderNavBarScreen from '../../components/headerNavBar/headerNavBar.screen';
import {
  Box,
  Button, CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem, Paper,
  Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography
} from '@mui/material';
import FooterScreen from '../../components/footer/footer.screen';
import * as React from 'react';
import TextFormatField from '../../components/textFieldFormat/TextFormatField.component';
import { useState } from 'react';
import axios from 'axios';
import formatToCurrency from '../../services/formatters/formatToCurrency.service';
import formatToBrDate from '../../services/formatters/formatToBrDate.service';


const MyContractsScreen = () => {

  const [body, setBody] = useState({documentNumber:'', birthday:''});
  const [contracts, setContracts] = useState([]);
  const [contractSelected, setContractSelected] = useState<undefined|any>(undefined);
  const [loading, setLoading] = useState(false);

  const changeContractSelected = (event:any) =>{
    const contractNumber = event.target.value;
    const contract = contracts.find((contract:any) => contract.contract_number === contractNumber);
    if(!contract){
      alert("Falha ao selecionar contrato! Atualize a página");
      return;
    }
    setContractSelected(contract);
  }

  const changBody = (name:any, value:any) =>{
    setBody({...body, [name]: value})
  }

  const findContracts = async () => {
    try {
      const { documentNumber, birthday } = body;

      if (!formIsValid(documentNumber, birthday)) return;
      const cpf = documentNumber.replace(/\D/g, '');
      const date = converterDataToYYYYMMDD(birthday);

      const queryFilter = `?document_number=${cpf}&birthday=${date}`;
      setLoading(true);
      const response = await axios.get(`https://api.nobli.com.br/contracts/list/by-client${queryFilter}`);

      let contracts = response.data;

      if (contracts.length === 0) {
        alert('Nenhum contrato encontrado');
        return;
      }

      setContracts(contracts);
      setContractSelected(contracts[0]);
    } catch (e) {
      alert('Erro ao requisitar informações');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderNavBarScreen />
      <Box sx={{ height: { md: '22vh', xs: '20vh' }, pt: 5, background: '#01576e'}}>
        <Container>
          <Box sx={{ color: '#ffffff' }}>
            <Box sx={{ paddingTop: { md: '5rem', xs: '3rem' } }}>
              <Typography sx={{fontSize:'36px', fontWeight:'bold', lineHeight:"1.15"}}>
                Meus contratos.
              </Typography>
              <Typography>
                Aqui você pode ver seus contratos.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ height: { md: '22vh' }, pb: 1}}>
        <Container>
          <Box sx={{ paddingTop: { md: '5rem', xs: '3rem' } }}>
            <Grid container spacing={4} justifyContent={"space-between"} alignItems="center">
              <Grid item md={4} xs={12}>
                <TextFormatField name={'documentNumber'} value={body.documentNumber} onChange={((event:any)=> changBody(event.target.name, event.target.value))} fullWidth label="Numero do CPF" variant="filled" placeholder={'999.999.999-99'} format={"###.###.###-##"} focused />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextFormatField name={'birthday'} onChange={((event:any)=> changBody(event.target.name, event.target.formattedValue))} fullWidth label="Nascimento" variant="filled" placeholder={'DD/MM/YYYY'} format={"##/##/####"} focused/>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button variant={"contained"} onClick={()=> findContracts()}>
                  Buscar contratos
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box sx={{ display: 'flex', justifyContent:"center", py:3}}>
        {loading && <CircularProgress />}
      </Box>
      {contractSelected &&
        <Box sx={{ py: 3}}>
          <Container>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <Box pb={'2rem'}>
                  <Typography sx={{color:'#707070', fontSize:'18px'}}>
                    Meu Contrato
                  </Typography>
                </Box>
                <Box>
                  <FormControl fullWidth variant={'filled'}>
                    <InputLabel id="selecione-seu-contrato-select-label">Selecione seu contrato</InputLabel>
                    <Select
                      labelId="selecione-seu-contrato-select-label"
                      id="selecione-seu-contrato-select"
                      value={contractSelected.contract_number}
                      label="Selecione seu contrato"
                      onChange={(event) => changeContractSelected(event)}
                    >
                      {contracts.map((contract:any) =>{
                        return <MenuItem key={contract.contract_number} value={contract.contract_number}>{`${contract.contract_number} - ${contract.status_description}`}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box py={2}>
                  <div style={{padding:"1rem 0 1rem 0"}}>
                    <div style={{color: "#707070"}}>Nº contrato</div>
                    <div style={{color: "black"}} id="contractNumberId">{contractSelected.contract_number}</div>
                  </div>
                  <div style={{padding:"0 0 1rem 0"}}>
                    <div style={{color: "#707070"}}>Valor financiado</div>
                    <div style={{color: "black"}} id="grantedAmountId">{formatToCurrency(contractSelected.granted_amount)}</div>
                  </div>
                  <div style={{padding:"0 0 1rem 0"}}>
                    <div style={{color: "#707070"}}>Parcelas</div>
                    <div style={{color: "black"}} id="currentInstallmentId">{contractSelected.installments.length.toString()}</div>
                  </div>
                  <div style={{padding:"0 0 1rem 0"}}>
                    <div style={{color: "#707070"}}>Contrato</div>
                    <div style={{color: "black"}} id="contractStatusId">{contractSelected.status_description}</div>
                  </div>
                </Box>
              </Grid>
              <Grid item md={7} xs={12}>
                <Box pb={'2rem'}>
                  <Typography sx={{color:'#707070', fontSize:'18px'}}>
                    Parcelas
                  </Typography>
                </Box>
                <Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Nª parcelas</TableCell>
                          <TableCell align="center">Vencimento</TableCell>
                          <TableCell align="center">Valor</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center" sx={{minWidth:"80px"}}>&nbsp;</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contractSelected.installments.sort((a:any, b:any) => a.installment_number - b.installment_number).map((installment:any) => (
                          <TableRow  key={installment.id}>
                            <TableCell align="center">{installment.installment_number}</TableCell>
                            <TableCell align="center">{formatToBrDate(installment.due_date)}</TableCell>
                            <TableCell align="center">{formatToCurrency(installment.amount)}</TableCell>
                            <TableCell align="center">{installment.paid_installment === '1' ? 'Pago' : 'Em aberto'}</TableCell>
                            <TableCell align="center">{buildDownloadButton(installment, contractSelected.status_description)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
      <FooterScreen />
    </>
  );
};

export default MyContractsScreen;

const buildDownloadButton = (installment:any, status_description:any) =>{
  if (installment.paid_installment === '0' && status_description === 'Ativo') {
    return <a href={installment.signed_url} target={'_blank'}>Baixar boleto</a>;
  }

  return '';
}
const documentNumberIsValid = (cpf:string) => {
  console.log(cpf);
  cpf = cpf.replace(/\D/g, '');

  if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
    return false;
  }

  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }

  let resto = 11 - (soma % 11);
  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpf.charAt(9))) {
    return false;
  }

  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }

  resto = 11 - (soma % 11);
  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  return resto === parseInt(cpf.charAt(10));
};

const dateIsValid = (data:string) => {
  const regexData = /^\d{2}\/\d{2}\/\d{4}$/;

  if (!regexData.test(data)) {
    return false; // Formato inválido
  }

  const partes = data.split('/');
  const ano = parseInt(partes[2]);
  const mes = parseInt(partes[1]);
  const dia = parseInt(partes[0]);

  // Verifica se o ano, mês e dia são válidos
  if (isNaN(ano) || isNaN(mes) || isNaN(dia)) {
    return false;
  }

  // Verifica o ano (assumindo um intervalo razoável)
  if (ano < 1900 || ano > 2100) {
    return false;
  }

  // Verifica o mês (deve estar entre 1 e 12)
  if (mes < 1 || mes > 12) {
    return false;
  }

  // Verifica o dia (levando em consideração os diferentes números de dias em cada mês)
  const diasNoMes = new Date(ano, mes, 0).getDate();
  return !(dia < 1 || dia > diasNoMes);
};


const formIsValid = (documentNumber:string, birthday:string) => {
  console.log(documentNumber);
  if (!documentNumberIsValid(documentNumber)) {
    alert('Número de CPF inválida');
    return false;
  }
  if (!dateIsValid(birthday)) {
    alert('Data de nascimento inválida');
    return false;
  }

  return true;
};

const converterDataToYYYYMMDD = (data:string) => {
  const partes = data.split('/');

  if (partes.length !== 3) {
    return 'Data inválida';
  }

  const dia = partes[0].padStart(2, '0');
  const mes = partes[1].padStart(2, '0');
  const ano = partes[2];

  return `${ano}-${mes}-${dia}`;
};
