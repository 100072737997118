import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import * as React from 'react';
import { isLogged } from '../../services/token/token.service';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined
  });

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

const HeaderNavBarScreen = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const meusContratos = () => {
    window.location.href = '/meus-contratos';
  };

  const toHome = () => {
    window.location.href = '/';
  };



  return (
    <HideOnScroll>
      <AppBar color={'transparent'} component='nav' sx={{ backgroundColor: '#FFF' }}>
        <Container>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, cursor: 'pointer' }} onClick={() => toHome()}>
              <img src={'/assets/nobli.svg'} alt={'Logo Nobli'} width={'160px'} />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
              >
                <MenuItem onClick={meusContratos}>
                  <Typography textAlign='center'>Meus Contratos</Typography>
                </MenuItem>
                {isLogged() &&
                  <MenuItem onClick={()=> window.location.href = '/cockpit'}>
                    <Typography textAlign='center'>Cockpit</Typography>
                  </MenuItem>
                }
              </Menu>
            </Box>
            <Box sx={{ mr: 2, display: { xs: 'flex', md: 'none' }, flexGrow: 1 }} onClick={() => toHome()}>
              <img src={'/assets/nobli.svg'} alt={'Logo Nobli'} width={'160px'} />
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                onClick={meusContratos}
              >
                Meus Contratos
              </Button>
            </Box>
            {isLogged() &&
              <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, px:1 }}>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={()=> window.location.href = '/cockpit'}
                >
                  Cockpit
                </Button>
              </Box>
            }


          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
};

export default HeaderNavBarScreen;
