import { RouteObject } from 'react-router-dom';
import React from "react";
import ContractDetailScreen from "../private/contractDetail/contractDetail.screen";
import FindContractsScreen from '../private/findContracts/findContract.screen';


const privateRoutes:RouteObject[] = [
  {
    path: "/cockpit/contrato-detalhe",
    element: <ContractDetailScreen />,
  },
  {
    path: "/cockpit/find-contracts",
    element: <FindContractsScreen />,
  },
  {
    path: "/cockpit/*",
    element: <FindContractsScreen />,
  }
];

export default privateRoutes;
