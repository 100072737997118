import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import React from "react";
import HomeScreen from "../public/home/home.screen";
import MyContractsScreen from "../public/myContracts/myContracts.screen";
import CockpitLoginScreen from "../public/cockpit/cockpitLogin.screen";

const browserRoutes:RouteObject[] = [
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/meus-contratos",
    element: <MyContractsScreen />,
  },
  {
    path: "/meus-contratos-cockpit",
    element: <CockpitLoginScreen />,
  },{
    path: "*",
    element: <HomeScreen/>,
  }
]
export default browserRoutes;
