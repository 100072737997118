import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  InputLabel, MenuItem, Select
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as React from 'react';
import { useState } from 'react';
import { getTokenInStorage } from '../../services/token/token.service';
import axios from 'axios';


const statusList = [
  {
    id: '1',
    status_code: 1,
    status_description: 'Ativo',
    reason_code: 1,
    reason_description: 'Contrato ativo',
  },
  {
    id: '2',
    status_code: 4,
    status_description: 'Encerrado',
    reason_code: 1,
    reason_description: 'Normal',
  },
  {
    id: '3',
    status_code: 4,
    status_description: 'Encerrado',
    reason_code: 2,
    reason_description: 'Pagto antecipado',
  },
  {
    id: '4',
    status_code: 4,
    status_description: 'Encerrado',
    reason_code: 3,
    reason_description: 'Execução',
  },
  {
    id: '5',
    status_code: 3,
    status_description: 'Cancelado',
    reason_code: 1,
    reason_description: 'Decisão nobli',
  },
  {
    id: '6',
    status_code: 3,
    status_description: 'Cancelado',
    reason_code: 2,
    reason_description: 'solicitacao cliente',
  },
];

const UpdateStatusModal = (props:{statusModal:boolean, handleCloseModal:any, contract:any, findContractById:any}) => {
  const {statusModal, handleCloseModal, contract, findContractById} = props;
  const [selectedStatus, setSelectedStatus]= useState('1');
  const [loading, setLoading] = useState(false);

  const sendUpdateStatus = async () => {
    try {

      const newStatusSelected = statusList.find((status) => status.id === selectedStatus);
      console.log(selectedStatus);
      console.log(newStatusSelected);
      if (!newStatusSelected) {
        alert('Selecione um status válido');
        return;
      }
      const body = {
        status_code: newStatusSelected.status_code,
        reason_code: newStatusSelected.reason_code,
        status_description: newStatusSelected.status_description,
        reason_description: newStatusSelected.reason_description,
      };

      setLoading(true);
      const { access_token } = getTokenInStorage();
      const response = await axios.put(`https://api.nobli.com.br/contracts/${contract.id}/contract-status`, body, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (response.status === 200) {
        await findContractById();
        alert('Status atualizado com sucesso!');
        handleCloseModal(false);
        return;
      }

      alert('Falha ao atualizar status');
      console.error(response);
    } catch (e) {
      alert('Falha ao atualizar status');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };


  return(
    <Dialog
      open={statusModal}
      onClose={()=>handleCloseModal(false)}
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle id="alert-dialog-title">
       Status do contrato
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">

          <div style={{padding:"0 0 1rem 0"}}>
            <div style={{color: "#707070"}}>Status atual do Contrato</div>
            <div style={{color: "black"}}>{`${contract.status_description}(${contract.status_code})/ ${contract.reason_description}(${contract.reason_code})`}</div>
          </div>

          <Box py={3}>
            <FormControl fullWidth variant={'outlined'} size={'small'}>
              <InputLabel id="selecione-seu-contrato-select-label">Selecione novo status</InputLabel>
              <Select
                labelId="selecione-seu-contrato-select-label"
                id="selecione-seu-contrato-select"
                value={selectedStatus}
                label="Selecione seu contrato"
                onChange={(event) => setSelectedStatus(event.target.value)}
              >
                {statusList.map((status) =>{
                  return (
                    <MenuItem value={status.id}>{`${status.status_description}(${status.status_code})/ ${status.reason_description}(${status.reason_code})`}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>handleCloseModal()}>Fechar</Button>
        <LoadingButton onClick={()=> sendUpdateStatus()} autoFocus loading={loading}>
          Atualizar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateStatusModal;
