const sessionStorageCockpitKey = 'COCKPIT_TOKEN';
const sessionStorageCockpitExpirationSession = 'COCKPIT_TOKEN_EXPIRATION';


export type AccessTokenType = {access_token: string};

const saveExpirationSession = () => {
  let currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 6);
  const timestamp = currentDate.getTime();
  sessionStorage.setItem(sessionStorageCockpitExpirationSession, timestamp.toString());
}

const sessionIsExpired = () =>{
  const timestamp = sessionStorage.getItem(sessionStorageCockpitKey);
  if(!timestamp){
    return true;
  }

  const parsedTimestamp = parseInt(timestamp);
  const currentTimestamp = new Date().getTime();

  if(currentTimestamp > parsedTimestamp){
    clearSession();
    return true;
  }

  return false;
}

const clearSession = () =>{
  sessionStorage.removeItem(sessionStorageCockpitKey);
  sessionStorage.removeItem(sessionStorageCockpitExpirationSession);
}

export const saveTokenInStorage = (token:AccessTokenType) => {
  const tokenString = JSON.stringify(token);
  sessionStorage.setItem(sessionStorageCockpitKey, tokenString);
  saveExpirationSession();
};

export const getTokenInStorage = ():AccessTokenType => {
  const tokenString = sessionStorage.getItem(sessionStorageCockpitKey);
  if(tokenString)
    return JSON.parse(tokenString);

  throw 'tokenString not found!';
};


export const isLogged = () => {
  sessionIsExpired();
  const cockpitUser = sessionStorage.getItem(sessionStorageCockpitKey);
  return !!cockpitUser;
};
