import { Box, Container, Grid, Typography } from '@mui/material';
import * as React from 'react';


const FooterScreen = () => {

  return(
    <Box sx={{backgroundColor:"#072627", width:"100%", color:"#cccccc"}}>
      <Container>
        <Box sx={{padding:'120px 0 120px 0'}}>
          <Box>
            <img src={'/assets/nobli-white.svg'} alt={"Logo Nobli"} width="125" />
          </Box>
          <Grid container spacing={3}>
            <Grid item md={10}>
              <p>
                <Typography fontSize={'12px'} textAlign={'justify'}>
                  A Nobli é uma fintech que ajuda investidores a obter crédito utilizando seus investimentos como garantia. Atuamos como correspondente bancário de instituições financeiras, de acordo com a Resolução no 4.935 de 2021 do Conselho Monetário Nacional. Atenção: nenhuma instituição pode cobrar qualquer valor antecipado para fazer a liberação de empréstimos ou financiamentos.
                </Typography>
              </p>

            </Grid>
            <Grid item md={2}>
              <Box textAlign={"center"}>
                <img src={'/assets/abfintechs-1.webp'} alt={"Logo Nobli"} width="100" />
              </Box>
            </Grid>
          </Grid>
          <Box>
            <p>
              <Typography fontSize={'12px'} textAlign={'center'}>
                © Nobli - Tecnologia e Serviços Financeiros Ltda.
              </Typography>
            </p>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default FooterScreen;
