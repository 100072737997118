import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import React from 'react';
import privateRoutes from './private.router';
import publicRoutes from './public.router';
import { isLogged } from '../services/token/token.service';


const Routers = () =>{

  let routes:RouteObject[] = publicRoutes;

  if(isLogged()){
    routes = routes.concat(privateRoutes)
  }

  const browserRouters = createBrowserRouter(routes);

  return <RouterProvider router={browserRouters} />
}

export default Routers;
