import { NumberFormatBase, NumericFormat, PatternFormat } from 'react-number-format';
import { TextField } from '@mui/material';

const TextFormatField = (props: any) => {
  return (
    <PatternFormat
      sx={props.sx}
      InputProps={{ disableUnderline: true }}
      focused={props.focused}
      fullWidth={props.fullWidth}
      label={props.label}
      name={props.name}
      id={props.id}
      variant={props.variant}
      placeholder={props.placeholder}
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      customInput={TextField}
      required={props.required}
      disabled={props.disabled}
      onValueChange={(values) => {
        props.onChange({
          target: {
            name: props?.name,
            value: values?.value,
            formattedValue: values.formattedValue
          },
        });
      }}
      prefix={props.prefix}
      valueIsNumericString
      format={props.format}
    />
  );
};

export default TextFormatField;
